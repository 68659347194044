import React from "react";
import OwlCarousel from "react-owl-carousel";
import { motion } from "framer-motion";
import vg1 from "../../assets/images/press/vg1.jpg";
import vg2 from "../../assets/images/press/vg2.jpg";
import elle1 from "../../assets/images/press/elle1.jpg";
import elle2 from "../../assets/images/press/elle2.jpg";
import hb1 from "../../assets/images/press/hb1.JPG";
import hb2 from "../../assets/images/press/hb2.JPG";
import cmp1 from "../../assets/images/press/cmp1.jpg";
import cmp2 from "../../assets/images/press/cmp2.jpg";
import lm1 from "../../assets/images/press/lm1.jpg";
import lm2 from "../../assets/images/press/lm2.jpg";
import lof1 from "../../assets/images/press/lof1.jpg";
import lof2 from "../../assets/images/press/lof2.jpg";
import lof3 from "../../assets/images/press/lof3.jpg";
import tpm1 from "../../assets/images/press/tpm1.jpg";
import tpm2 from "../../assets/images/press/tpm2.jpg";
import mc3 from "../../assets/images/press/mc3.jpg";
import mc4 from "../../assets/images/press/mc4.jpg";  
import bg1 from "../../assets/images/press/bg1.jpg";
import bg2 from "../../assets/images/press/bg2.jpg";
import vg3 from "../../assets/images/press/vg3.png";
import vg4 from "../../assets/images/press/vg4.png";
import vg5 from "../../assets/images/press/vg5.jpg";
import gla1 from "../../assets/images/press/gla1.png";
import gla2 from "../../assets/images/press/gla2.png";
import glam1 from "../../assets/images/press/glam1.png";
import lcl1 from "../../assets/images/press/lcl1.jpeg";
import hc1 from "../../assets/images/press/hc1.jpeg";
import hc2 from "../../assets/images/press/hc2.jpeg";
import gla3 from "../../assets/images/press/gla3.png";
import gla4 from "../../assets/images/press/gla4.png";
import cmp3 from "../../assets/images/press/cmp3.JPG";
import cmp4 from "../../assets/images/press/3.JPG";
import slide4 from "../../assets/images/press/4.jpg";
import slide5 from "../../assets/images/press/5.jpg";
import slide6 from "../../assets/images/press/6.jpg";
import slide7 from "../../assets/images/press/7.JPG";
import mc1 from "../../assets/images/press/mc1.JPG";
import mc2 from "../../assets/images/press/mc2.JPG";
import Video from "../../assets/videos/video.mp4";
import styled from "@emotion/styled";
import MiddleVideo from "../Video/Video";
import useMediaQuery from "../../hooks/useMediaQuery";

const owl_options = {
  items: 1,
  nav: false,
  // navText:["<div className='nav-btn prev-slide'></div>","<div className='nav-btn next-slide'></div>"],
  rewind: true,
  lazyLoad: true,
  autoplay: 12500,
  autoplaySpeed: 2500,
  slideBy: 1,
  dots: true,
  dotsEach: true,
  dotData: true,
  responsive: {
    0: {
      nav: false,
    },
    480: {
      nav: false,
    },
    768: {
      nav: true,
    },
    992: {
      nav: true,
    },
  },
};



const PressSlider = () => {
  const videoRef = React.useRef();
  const isMobile = useMediaQuery("(max-width: 768px)")
  const slides = [
    {
      title: "L'Officiel",
      images: [slide4],
    },
    {
      title: "Vogue",
      images:  [vg1, vg2],
    },
    {
      title: "Elle Magazine",
      images:  [elle1, elle2],
    },
    {
      title: "Harper's Bazaar",
      images:  [hb1, hb2],
    },
    {
      title: "Marie Claire",
      images:  [mc1, mc2],
    },
    {
      title: "Cosmopolitan",
      images:  [cmp1, cmp2]
    },
    {
      title: "La Mode",
      images:  [lm1, lm2]
    },
    {
      title: "L'Officiel",
      images:  [lof1, lof2],
    },
    {
      title: "TPM Magazine",
      images:  [tpm1, tpm2],
    },
    {
      title: "Marie Claire",
      images: [mc3, mc4],
    },
    {
      title: "BloggingTales",
      images: [bg1, bg2]
    },
    {
      title: "Vogue",
      images:  [vg3, vg4]
    },
    {
      title: "Vogue",
      images: [vg5],
    },
    {
      title: "Glamour",
      images: [gla1, gla2]
    },
    {
      title: "House of Coco",
      images: [hc1, hc2]
    },
    {
      title: "Glamour",
      images:  [gla3, gla4]
    },
    {
      title: "Cosmopolitan",
      images:  [cmp3, cmp4]
    },
    {
      title: "Glamour",
      images: [glam1],
    },
    {
      title: "Lucy Lara",
      images: [lcl1],
    },
  
  ];
  const setPlayBackRate = () => {
    videoRef.current.playbackRate = 0.6;
  };
  return (
    <div>
      <main className="main">
        <div className="intro-slider-container">
          <div
            className="intro-slider owl-carousel owl-theme owl-nav-inside owl-light"
            data-toggle="owl"
          >
            <OwlCarousel {...owl_options} nav>
              {slides.map((slide, index) => (
                <div
                  className="intro-slide"
                  style={{ backgroundColor: "#d3d6d0" }}
                >
                  <div className="container-fluid intro-content">
                    <div className="row justify-content-between">
                      <div className="col-6 col-md-6 col-lg-5">
                        <h3 className="intro-subtitle">{slide.title}</h3>
                          </div>
                      <div className="col-6 col-md-6 col-lg-5">
                        <div className="intro-img d-flex justify-content-between ">
                          {!isMobile ? (
                            <img
                              src={slide.images[1] ? slide.images[1] : slide.images[0]}
                              className="img-fluid"
                              alt=""
                            />
                          )
                            : (
                              slide.images.map((image, index) => (
                                <img
                                  src={image}
                                  className="img-fluid"
                                  alt=""
                                />
                            )
                              ))
                          }
                        </div>
                      </div>
                    </div>  
                  </div>
                </div>
              ))}
             

              {/* <div 
                className="intro-slide"
                style={{ backgroundColor: "#d3d6d0" }}
              >
                <div className="container intro-content">
                  <div className="row justify-content-between">
                    <div className="col-12 col-md-6 col-lg-5">
                      <h3 className="text-white">Elle Magazine</h3>
                    </div>
                    <div className="col-12 col-md-6 col-lg-5">
                      <div className="intro-img d-flex">
                        <img src={elle1} alt="" />
                        <img src={elle2} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="intro-slide"
                style={{ backgroundColor: "#d3d6d0" }}
              >
                <div className="container intro-content">
                  <div className="row justify-content-between">
                    <div className="col-12 col-md-6 col-lg-5">
                      <h3 className="">Cosmopolitan</h3>
                    </div>
                    <div className="col-12 col-md-6 col-lg-5">
                      <div className="intro-img">
                        <img src={slide3} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="intro-slide"
                style={{ backgroundColor: "#d3d6d0" }}
              >
                <div className="container intro-content">
                  <div className="row justify-content-between">
                    <div className="col-12 col-md-6 col-lg-5">
                      <h3 className="">L'Officiel</h3>
                    </div>
                    <div className="col-12 col-md-6 col-lg-5">
                      <div className="intro-img">
                        <img src={slide4} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div
                className="intro-slide"
                style={{ backgroundColor: "#d3d6d0" }}
              >
                <div className="container intro-content">
                  <div className="row justify-content-between">
                    <div className="col-12 col-md-6 col-lg-5">
                      <h3 className="">La Mode</h3>
                    </div>
                    <div className="col-12 col-md-6 col-lg-5">
                      <div className="intro-img">
                        <img src={slide5} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="intro-slide"
                style={{ backgroundColor: "#d3d6d0" }}
              >
                <div className="container intro-content">
                  <div className="row justify-content-between">
                    <div className="col-12 col-md-6 col-lg-5">
                      <h3 className="">Marie Claire</h3>
                    </div>
                    <div className="col-12 col-md-6 col-lg-5">
                      <div className="intro-img d-flex">
                        <img src={mc1} alt="" />
                        <img src={mc2} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="intro-slide"
                style={{ backgroundColor: "#d3d6d0" }}
              >
                <div className="container intro-content">
                  <div className="row justify-content-between">
                    <div className="col-12 col-md-6 col-lg-5">
                      <h3 className="">Harpers Bazaar</h3>
                    </div>
                    <div className="col-12 col-md-6 col-lg-5">
                    <div className="intro-img d-flex">
                        <img src={hb1} alt="" />
                        <img src={hb2} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              {/* <div className="intro-slide" style={{ backgroundImage: `url(${slide1})` }} >
                                <div className="container text-center intro-content">
                                    <h3 className="text-white intro-subtitle">Be Pink with our beautiful collection.</h3>
                                    <h1 className="text-white intro-title">Teresa</h1>
                                    <motion.a whileHover={{ scale: 1.08 }} whileTap={{ scale: 1.05 }} href="/shop" className="btn btn-outline-white-4">
                                        <span>Discover More</span>
                                    </motion.a>
                                </div>
                            </div> */}
            </OwlCarousel>
          </div>
        </div>
      </main>
    </div>
  );
};
PressSlider.Video = styled.video`
  width: 100%;
  height: 100%;
  max-height: 950px;
  object-fit: cover;
  position: relative;
  display: block;
  top: 0;
  left: 0;
  z-index: 1;
`;
export default PressSlider;
