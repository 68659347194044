import React from "react";
import { Navigate, useNavigate } from "react-router-dom";

const ShopProduct = ({item}) => {
    const navigate = useNavigate();
    const handleProduct = (item) => {
        navigate(`/shop/${item.name}`, {state: {item}})
    }
    return (
            <div className="col-6 col-md-4 col-lg-4 col-xl-4">
                <div onClick={() => handleProduct(item)} className="product">
                    <figure className="product-media">
                        {/*<span className="product-label label-new">New</span>*/}
                        <span>
                        <img src={item.image} alt="Product " className="product-image" />
                    </span>

                        {/* <div className="product-action-vertical">
                            <span className="btn-product-icon btn-wishlist btn-expandable"  onClick={ ()=> {} }><span>Add to Wishlist</span></span>
                        </div> */}
{/* 
                        <div className="product-action action-icon-top">

                                       <span className='btn-product btn-cart' onClick={() => {}} ><span>Add to Cart</span></span>
                        </div> */}
                    </figure>

                    <div className="product-body">
                        {/*<div className="product-cat">*/}
                        {/*    <span>{item.name}</span>*/}
                        {/*</div>*/}
                        <h3 className="product-title" style={{ fontFamily: 'ConmorantGaramontLight', textAlign: 'center'}}>{item.name}</h3>
                        {/* <div className="product-price" style={{fontFamily: 'ConmorantGaramontLight' ,textAlign: 'center'}}>
                            ${item.price}.00
                        </div> */}
                        {/* <div className="ratings-container"> */}
                            {/*<div className="ratings">*/}
                            {/*    <div className="ratings-val" style={{ width: '0%' }}></div>*/}
                            {/*</div>*/}
                            {/*<span className="ratings-text">( 13 Reviews )</span>*/}
                        {/* </div> */}

                        
                    </div>
                </div>
            </div>
    )
}

export default ShopProduct;