import React from "react";
import styled from "@emotion/styled";
const YGWorldPage = () => {
    return (
        <YGWorldPage.Container>
            <YGWorldPage.FrontImageContainer>
                <YGWorldPage.ImageBackground src="https://static.wixstatic.com/media/58afcb_ee8699fcdc7442b9b628d84716c93c62~mv2.jpeg/v1/fill/w_800,h_800,al_c,q_85,enc_auto/1599062929950.jpeg" />
            </YGWorldPage.FrontImageContainer>
        </YGWorldPage.Container>
    );
}

YGWorldPage.Container = styled.div`
    //display: flex;
    height: 100%;
      width: 100%;
      min-height: 100vh;
      background-image: url("https://static.wixstatic.com/media/58afcb_35421a889a7949ca8a2a4250abf4d89e~mv2.jpg/v1/fill/w_2208,h_2144,al_c,q_90,enc_auto/58afcb_35421a889a7949ca8a2a4250abf4d89e~mv2.jpg");
      color: #fff;
      z-index: 0;

    @media (max-width: 768px) {
      //display: block;
      min-height: 100%;
    }
`;

YGWorldPage.FrontImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 100px;
  margin-right: 100px;
    //align-items: center;
  overflow: hidden;
  `;

YGWorldPage.ImageBackground = styled.img`
  border-radius: 50%;
  
  width: 350px;
    height: 350px;
  object-fit: cover;
  position: static;
`;

YGWorldPage.BackImage = styled.img`
    width: 100%;
    height: 100%;
    max-width: 400px;
    max-height: 550px;
    //object-fit: cover;
    position: absolute;
    top: 180px;
    left: 100px;
    z-index: -1;


  
  @media (max-width: 1285px) {
    display: none;
  }
`;

YGWorldPage.FrontImage = styled.img`
    width: 100%;
    height: 100%;
    max-width: 430px;
    max-height: 600px;
    position: absolute;
    top: 220px;
    left: 170px;
    z-index: 1;
    
    @media (max-width: 1285px) {
      max-height: 100%;
      top: 5px;
      left: 0;
      position: relative;

      align-items: center;
      justify-content: center;
      display: block;
    }
`;

YGWorldPage.TextContainer = styled.div`
    width: 65%;
    height: 100%;
    min-height: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
   @media (max-width: 1285px) {
    width: 95%;
    height: 55%;
     
    align-items: center;
    display: block;
   }
`;

YGWorldPage.Title = styled.h1`
  font-family: 'ConmorantGaramondLight', serif;
    font-size: 2.5rem;
    font-weight: 600;
    color: #2e2e2e;
    text-align: center;
    margin-bottom: 1.5rem;
    z-index:  2;
    top: 35px;
    position: relative;
    @media (max-width: 1280px) {
        font-size: 1.5rem;
        top: 20px;
    }
`;

YGWorldPage.Message = styled.p`
  font-family: 'ConmorantGaramondLight', serif;
    font-size: 1.5rem;
    font-weight: 500;
    color: #2e2e2e;
    text-align: justify;
    margin-bottom: 1.5rem;
    z-index:  2;
    top: 35px;
    left: 40px;
    position: relative;
    @media (max-width: 1280px) {
        font-size: 1rem;
        top: 20px;
         left: 10px;
      
    }
`;
YGWorldPage.SignImage = styled.img`
    width: 100%;
    height: 100%;
    max-width: 350px;
    max-height: 327px;
    //object-fit: cover;
 
    left: 0px;
    z-index: 1;
    @media (max-width: 1280px) {
        max-width: 150px;
        max-height: 175px;
        top: 0;
        left: 0;
        display: block;
    }
`;

    

    
    

export default YGWorldPage;