import YGLOGO from '../../assets/images/logo.webp';
import {MetaTags} from "react-meta-tags";

const CommingSoonPage = () => {
    return (
        <>
            <MetaTags>
                <title>Yoanny Garcia | COMING SOON</title>
            </MetaTags>
            <section className={`flex justify-center items-center min-h-screen`}>
                <div className={`flex-col text-center`}>
                    <img src={YGLOGO} width={500} alt="img-fluid"/>
                    <h3 className={`font-conmorant`}>COMING SOON</h3>
                </div>
            </section>
        </>

     );
}

export default CommingSoonPage;