import React from "react";
import styled from "@emotion/styled";
import {ShopProduct, ShopSlider, ShopToolBox} from "../../components";
import Colette from '../../assets/images/bags/Colette.jpg'
import Leonore from '../../assets/images/bags/Leonore.jpg'
import Amira from '../../assets/images/bags/Amira.jpg'
import Alessia from '../../assets/images/bags/Alessia.jpg'
import Abby from '../../assets/images/bags/Abbby.jpg'
import Teresa from '../../assets/images/bags/Teresa.jpg'
import Vittoria from '../../assets/images/bags/Vittoria.jpg' 
import Rounded from '../../assets/images/bags/VittoriaR.jpg'
import Squared from '../../assets/images/bags/squared.jpg'
import Claire from '../../assets/images/bags/Claire.jpg'
import Regina2 from '../../assets/images/bags/Regina2.jpg'
import Arabela from '../../assets/images/bags/Arabela.jpg'
import RegMaria from '../../assets/images/bags/RegMaria.jpg'
import MiniCamelia from '../../assets/images/bags/MiniCamelia.jpg'
import Alize from '../../assets/images/bags/Alize.jpg'
import Velle from '../../assets/images/bags/Velle.jpg'
import Erah from '../../assets/images/bags/erah.jpg'
import Atenas from '../../assets/images/bags/Atenas.jpg'
import Xiana from '../../assets/images/bags/Xiana.jpg'
import Palmeira from '../../assets/images/bags/Palmeira.jpg'
import BagsBanner from '../../assets/images/banner-handbags-01.png'
// 20 leonores
import Leonore1 from '../../assets/images/bags/Leonore/Leonore1.jpg'
import Leonore2 from '../../assets/images/bags/Leonore/Leonore2.jpg'
import Leonore3 from '../../assets/images/bags/Leonore/Leonore3.jpg'
import Leonore4 from '../../assets/images/bags/Leonore/Leonore4.jpg'
import Leonore5 from '../../assets/images/bags/Leonore/Leonore5.jpg'
import Leonore6 from '../../assets/images/bags/Leonore/Leonore6.jpg'
import Leonore7 from '../../assets/images/bags/Leonore/Leonore7.jpg'
import Leonore8 from '../../assets/images/bags/Leonore/Leonore8.jpg'
import Leonore9 from '../../assets/images/bags/Leonore/Leonore9.jpg'
import Leonore10 from '../../assets/images/bags/Leonore/Leonore10.jpg'
import Leonore11 from '../../assets/images/bags/Leonore/Leonore11.jpg'
import Leonore12 from '../../assets/images/bags/Leonore/Leonore12.jpg'
import Leonore13 from '../../assets/images/bags/Leonore/Leonore13.jpg'
import Leonore14 from '../../assets/images/bags/Leonore/Leonore14.jpg'
import Leonore15 from '../../assets/images/bags/Leonore/Leonore15.jpg'
import Leonore16 from '../../assets/images/bags/Leonore/Leonore16.jpg'
import Leonore17 from '../../assets/images/bags/Leonore/Leonore17.jpg'
import Leonore18 from '../../assets/images/bags/Leonore/Leonore18.jpg'
import Leonore19 from '../../assets/images/bags/Leonore/Leonore19.jpg'
import Leonore20 from '../../assets/images/bags/Leonore/Leonore20.jpg'
// 6 Vround
import VittoriaR1 from '../../assets/images/bags/Vittoria/VRound1.jpg'
import VittoriaR2 from '../../assets/images/bags/Vittoria/VRound2.jpg'
import VittoriaR3 from '../../assets/images/bags/Vittoria/VRound3.jpg'
import VittoriaR4 from '../../assets/images/bags/Vittoria/VRound4.jpg'
import VittoriaR5 from '../../assets/images/bags/Vittoria/VRound5.jpg'
import VittoriaR6 from '../../assets/images/bags/Vittoria/VRound6.jpg'
//6 VHexa
import VittoriaH1 from '../../assets/images/bags/Vittoria/VHexa1.jpg'
import VittoriaH2 from '../../assets/images/bags/Vittoria/VHexa2.jpg'
import VittoriaH3 from '../../assets/images/bags/Vittoria/VHexa3.jpg'
import VittoriaH4 from '../../assets/images/bags/Vittoria/VHexa4.jpg'
import VittoriaH5 from '../../assets/images/bags/Vittoria/VHexa5.jpg'
import VittoriaH6 from '../../assets/images/bags/Vittoria/VHexa6.jpg'
//4 VSquare
import VittoriaS1 from '../../assets/images/bags/Vittoria/VSquare1.jpg'
import VittoriaS2 from '../../assets/images/bags/Vittoria/VSquare2.jpg'
import VittoriaS3 from '../../assets/images/bags/Vittoria/VSquare3.jpg'
import VittoriaS4 from '../../assets/images/bags/Vittoria/VSquare4.jpg'
//10 Teresa
import Teresa1 from '../../assets/images/bags/Teresa/Teresa1.jpg'
import Teresa2 from '../../assets/images/bags/Teresa/Teresa2.jpg'
import Teresa3 from '../../assets/images/bags/Teresa/Teresa3.jpg'
import Teresa4 from '../../assets/images/bags/Teresa/Teresa4.jpg'
import Teresa6 from '../../assets/images/bags/Teresa/Teresa6.jpg'
import Teresa7 from '../../assets/images/bags/Teresa/Teresa7.jpg'
import Teresa8 from '../../assets/images/bags/Teresa/Teresa8.jpg'
import Teresa9 from '../../assets/images/bags/Teresa/Teresa9.jpg'
import Teresa10 from '../../assets/images/bags/Teresa/Teresa10.jpg'
//10 Colette
import Colette1 from '../../assets/images/bags/Colette/Colette1.jpg'
import Colette2 from '../../assets/images/bags/Colette/Colette2.jpg'
import Colette3 from '../../assets/images/bags/Colette/Colette3.jpg'
import Colette4 from '../../assets/images/bags/Colette/Colette4.jpg'
import Colette5 from '../../assets/images/bags/Colette/Colette5.jpg'
import Colette6 from '../../assets/images/bags/Colette/Colette6.jpg'
import Colette7 from '../../assets/images/bags/Colette/Colette7.jpg'
import Colette8 from '../../assets/images/bags/Colette/Colette8.jpg'
import Colette9 from '../../assets/images/bags/Colette/Colette9.jpg'
import Colette10 from '../../assets/images/bags/Colette/Colette10.jpg'
//4 Amira
import Amira1 from '../../assets/images/bags/Amira/Amira1.jpg'
import Amira2 from '../../assets/images/bags/Amira/Amira2.jpg'
import Amira3 from '../../assets/images/bags/Amira/Amira3.jpg'
import Amira4 from '../../assets/images/bags/Amira/Amira4.jpg'

//6 Abby
import Abby1 from '../../assets/images/bags/Abby/Abby1.jpg'
import Abby2 from '../../assets/images/bags/Abby/Abby2.jpg'
import Abby3 from '../../assets/images/bags/Abby/Abby3.jpg'
import Abby4 from '../../assets/images/bags/Abby/Abby4.jpg'
import Abby5 from '../../assets/images/bags/Abby/Abby5.jpg'
import Abby6 from '../../assets/images/bags/Abby/Abby6.jpg'

//3 Alessia
import Alessia1 from '../../assets/images/bags/Alessia/Alessia1.jpg'
import Alessia2 from '../../assets/images/bags/Alessia/Alessia2.jpg'
import Alessia3 from '../../assets/images/bags/Alessia/Alessia3.jpg'

//2 Velle
import Velle1 from '../../assets/images/bags/Velle/Velle1.jpg'
import Velle2 from '../../assets/images/bags/Velle/Velle2.jpg'

//3 Camelia
import Camelia from '../../assets/images/bags/Camelia/Camelia.jpg'
import Camelia1 from '../../assets/images/bags/Camelia/Camelia1.jpg'
import Camelia2 from '../../assets/images/bags/Camelia/Camelia2.jpg'
import Camelia3 from '../../assets/images/bags/Camelia/Camelia3.jpg'
import Camelia4 from '../../assets/images/bags/Camelia/Camelia4.jpg'
//4 Claire
import Claire1 from '../../assets/images/bags/Claire/Claire1.jpg'
import Claire2 from '../../assets/images/bags/Claire/Claire2.jpg'
import Claire3 from '../../assets/images/bags/Claire/Claire3.jpg'
import Claire4 from '../../assets/images/bags/Claire/Claire4.jpg'

//2 Alize
import Alize1 from '../../assets/images/bags/Alize/Alize1.jpg'
import Alize2 from '../../assets/images/bags/Alize/Alize2.jpg'

//2Regina
import Regina1 from '../../assets/images/bags/Regina/Regina1.jpg'
import Regina22 from '../../assets/images/bags/Regina/Regina2.jpg'

//4 Arabela
import Arabela1 from '../../assets/images/bags/Arabela/Arabela1.jpg'
import Arabela2 from '../../assets/images/bags/Arabela/Arabela2.jpg'
import Arabela3 from '../../assets/images/bags/Arabela/Arabela3.jpg'
import Arabela4 from '../../assets/images/bags/Arabela/Arabela4.jpg'

//3Xiana
import Xiana1 from '../../assets/images/bags/Xiana/Xiana1.jpg'
import Xiana2 from '../../assets/images/bags/Xiana/Xiana2.jpg'
import Xiana3 from '../../assets/images/bags/Xiana/Xiana3.jpg'

//3 ReginaMaria
import ReginaMaria1 from '../../assets/images/bags/ReginaMaria/ReginaMaria1.jpg'
import ReginaMaria2 from '../../assets/images/bags/ReginaMaria/ReginaMaria2.jpg'
import ReginaMaria3 from '../../assets/images/bags/ReginaMaria/ReginaMaria3.jpg'

//2 Atenas
import Atenas1 from '../../assets/images/bags/Atenas/Atenas1.jpg'
import Atenas2 from '../../assets/images/bags/Atenas/Atenas2.jpg'

//3 Palmeira
import Palmeira1 from '../../assets/images/bags/Palmeira/Palmeira1.jpg'
import Palmeira2 from '../../assets/images/bags/Palmeira/Palmeira2.jpg'
import Palmeira3 from '../../assets/images/bags/Palmeira/Palmeira3.jpg'

const items = [
    {
        id: 6,
        image: Teresa,
        name: "Teresa",
        price: 785.00,
        colors: ["#000", '#c06500', '#ff66cc', '#c0c0c0', '#0000ff', '#FF0000', '#d09800'],
        sizes: ['Mini', 'Maxi'],
        images:[
            Teresa1,
            // Teresa2,
            // Teresa3,
            Teresa4,
            Teresa6,
            Teresa7,
            Teresa8,
            Teresa9,
            Teresa10,
        ],
        description: 'Woven bag in lambskin and acrylic. White/Clear/Black and Gold/Silver appliques in low relief.'
    },
    {
        id: 1,
        image: Leonore,
        name: "Leonore",
        price: 531.00,
        //colors blue, yellow, red, gold, silver, shyny red, aqua blue, black, pink, green
        colors: ["#0000ff", "#ED3C3F", "#FFFF00", "#ff0000", "#ffd700", "#c0c0c0", "#00fffF", "#000", "#F0F", "#0F0"],
        description: 'Bag with handmade rosette handle made out of leather with applique on front.',
        images: [
            Leonore10,Leonore8,
            Leonore19,
            Leonore18,
            Leonore6,
            Leonore4,
            Leonore14,
            Leonore2,
            Leonore12,
            Leonore16,
            
            
            // Leonore9,
            // Leonore7,
            // Leonore20,
            // Leonore17,
            // Leonore5,
            // Leonore3,
            // Leonore13,
            // Leonore1,
            // Leonore11,
            // Leonore15,
        ]
    },
    {
        id: 2,
        image: Colette,
        name: "Colette",
        price: 336.00,
        description: 'Irregular hexagonal leather, textile or cork bag.',
        colors: ["#80471c", "#8DC2B5", "#c0c0c0", '#000', '#CD5e77'],
        images: [
            // Colette2,
            // Colette1,
            // Colette3,
            // Colette4,
            // Colette5,
            Colette7,
            
            Colette6,
            Colette9,
            Colette8,
            Colette10,
        ]
    },
    {
        id: 3,
        image: Amira,
        name: "Amira",
        price: 435.00,
        description: 'Bucket leather bag with acrylic and crystal details all over.',
        colors: ["#c0c0c0", '#000'],
        sizes: ['Mini', 'Maxi'],
        images: [
            Amira2,
            Amira1,
            Amira4,
            Amira3,
        ]

    },

    {
        id: 4,
        image: Alessia,
        name: "Alessia",
        price: 588.00,
        description: 'Handbag with a unique and flirty form. Perfect combination of acrylic, textile or leather.',
        colors: ["#000", "#f5f5f5", "#56070C"],
        images: [
            Alessia3,
            Alessia1,
            Alessia2,
        ],
    },
    {
        id: 5,
        image: Abby,
        name: "Abby 2.0",
        price: 588.00,
        colors: ["#FFE4b4", "#000", '#C19A6B' ],
        images: [
            Abby1,
            Abby2,
            Abby3,
            Abby4,
            Abby5,
            Abby6,
        ],
    },

    {
        id: 7,
        image: Vittoria,
        name: "Vittoria Hexa",
        price: 610.00,
        //colors silver black and blueu
        colors: ["#c0c0c0", "#000", "#0000ff"],
        description: 'Hexagonal bag made out of leather or textile with appliques on front.',
        sizes: ['Mini', 'Midi'],
        images: [
            VittoriaH1,
            VittoriaH2,
            VittoriaH3,
            VittoriaH4,
            VittoriaH5,
            VittoriaH6,
        ],
    },
    {
        id: 8,
        image: Rounded,
        name: "Vittoria Round",
        price: 558.00,
        colors: ["#f7ef8a", '#E75480', '#8db600'],
        sizes: ['Mini', 'Midi'],
        images: [
            VittoriaR1,
            VittoriaR2,
            VittoriaR3,
            VittoriaR5,
            VittoriaR4,
            VittoriaR6,
        ],
        description: 'Round bag made out of leather or textile with appliques on front.'
    },
    {
        id: 9,
        image: Squared,
        name: "Vittoria Square",
        price: 558.00,
        colors: ["#FFDB58", '#000'],
        images: [
            VittoriaS1,
            VittoriaS2,
            VittoriaS3,
            VittoriaS4,
        ],
        description: 'Mini square bag made out of leather or textile with appliques on front.'
    },
    {
        id: 10,
        image: Claire,
        name: "Claire",
        price: 558.00,
        // brown silver black white
        colors: ["#80471c", "#c0c0c0", "#000", "#f5f5f5"],
        images: [
            Claire1,
            Claire2,
            Claire3,
            Claire4,
            
        ],
    },
    {
        id: 11,
        image: Regina2,
        name: "Regina 2.0",
        price: 558.00,
        colors: ["#000", '#ffff00'],
        images: [
            Regina1,
            Regina22,
        ],
        
    },
    {
        id: 13,
        image: Arabela,
        name: "Arabela",
        price: 558.00,
        colors: ["#fbbf7f", "#937d6a", "#ff0000", "#ffff00"],
        images : [
            Arabela1,
            Arabela2,
            Arabela3,
            Arabela4,
        ],
        
    },
    {
        id: 20,
        image: Xiana,
        name: "Xiana",
        price: 558.00,
        colors: [ '#664c6b', "#000",'#482231'],
        images : [
            Xiana2,
            Xiana3,
            Xiana1,
        ],
    },
    {
        id: 14,
        image: RegMaria,
        name: "Regina Maria",
        price: 558.00,
        colors: ['#1c8aca','#dfb460',"#fdd400"],
        images : [
            ReginaMaria1,
            ReginaMaria2,
            ReginaMaria3,
        ],

    },
    {
        id: 16,
        image: Alize,
        name: "Alize",
        price: 558.00,
        // black and bright gold
        colors: ["#000", '#F7ef8a'],
        images : [
            Alize1,
            Alize2,
        ],
    },
    {
        id: 17,
        image: Velle,
        name: "Velle",
        price: 558.00,
        sizes: ['Mini', 'Maxi'],
        images: [
            Velle2,
            Velle1
        ],
        colors: ["#000", '#8DC2B5'],
    },
    {
        id: 18,
        image: Erah,
        name: "Camelia",
        price: 558.00,
        sizes: ['Mini', 'Maxi'],
        images: [
            Camelia4
        ],
        colors: ['#84543c'],
    },
    {
        id: 19,
        image: Atenas,
        name: "Atenas",
        price: 558.00,
        colors: ['#877f8a',"#82491d"],
        images : [
            Atenas1,
            Atenas2,
        ],
        
    },
    {
        id: 15,
        image: MiniCamelia,
        name: "Camelia 2.0",
        price: 558.00,
        sizes: ['Mini', 'Maxi'],
        images: [
            Camelia,
            Camelia1,
            Camelia2,
            Camelia3
        ],
        colors: ["#80471c", '#f5f5dc', '#000','#006400'],
    },
    {
        id: 21,
        image: Palmeira,
        name: "Palmeira",
        price: 558.00,
        colors: ["#0b8c82", '#0e5cae', '#f59500'],
        images : [
            Palmeira3,
            Palmeira2,
            Palmeira1,
        ],
    },
    ];
const ShopPage = () => {
    return <React.Fragment>
        <div className="container-fluid">
            <img className="rounded" width={'100%'} src={BagsBanner} alt="bags banner"/>
            </div>
        <ShopPage.MessageContainer>
            <ShopPage.Message>
                I like to create trends, not follow them.
            </ShopPage.Message>
            <ShopPage.Yoanny> - Yoanny Garcia </ShopPage.Yoanny>
        </ShopPage.MessageContainer>

        <ShopPage.Container>
            <div className="mt-3 page-content">

                <div className="container-fluid">
                    {/*<ShopToolBox/>*/}
                    <div className="products">


                <div className="row">
                {items.map(item => <ShopProduct key={item.id} item={item}/>)}
                </div>
                </div>
                </div>
                </div>
        </ShopPage.Container>

    </React.Fragment>;
}


ShopPage.MessageContainer = styled.div`
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    width: 100%;
    height: 100%;
    min-height: 5vh;
    max-height: 8vh;
`;

ShopPage.Message = styled.h3`
    font-family: 'ConmorantGaramontLight' !important;
    font-size: 35px;
    font-weight: 600;
    color: #2e2e2e;
    text-align: center;
    position: relative;
    top: 25px;
    z-index:  2;
   @media (max-width: 768px) {
    font-size: 2rem;
     
   }
`;

ShopPage.Yoanny = styled.p`
    font-family: 'ConmorantGaramontLight' !important;
    font-size: 25px;
    font-weight: 200;
    color: #2e2e2e;
    text-align: justify;
    margin-bottom: 1.5rem;
    z-index:  2;

    @media (max-width: 768px) {
        font-size: 1.5rem;
          top: 20px;
    }
`;

ShopPage.CarouselContainer = styled.div`
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    @media (max-width: 768px) {
        width: 95%;
        height: 55%;
        align-items: center;
        display: block;
      
    }
`;
ShopPage.Carousel = styled(ShopSlider)`
    width: 100%;
    height: 100%;

`;

ShopPage.Container = styled.div`
    width: 97%;
    height: 100%;
    //position: relative;
    min-height: 80vh;
    //position: relative;
    left: 0;
    top: 70px;
    @media (max-width: 768px) {
        width: 100%;
        height: 100%;
        position: relative;
        left: 0;
        top: 0;
    }
        
`;
export default ShopPage;