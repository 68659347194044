import React from "react";
import styled from "@emotion/styled";
import { ShopProduct, ShopSlider, ShopToolBox } from "../../components";
import Gala from "../../assets/images/clutches/Gala.jpg";
import Venus from "../../assets/images/clutches/Venus.png";
import Luce from "../../assets/images/clutches/Luce.png";
import Stelle from "../../assets/images/clutches/Stelle.png";
import Parasole from "../../assets/images/clutches/Parasole.jpg";
import LPM from "../../assets/images/clutches/LPM.jpg";
import TereClassic from "../../assets/images/clutches/TereClassic.jpg";
import TereSquare from "../../assets/images/clutches/TereSquare.jpg";
import ClutchesBanner from "../../assets/images/clutches-banner.png";
import TereRect from "../../assets/images/clutches/TereRect.jpg";
import TereHexa from "../../assets/images/clutches/TereHexa.jpg";
import Hannah from "../../assets/images/clutches/Hannah.jpg";
import CocoaCaramel from "../../assets/images/clutches/CocoaCaramel.jpg";
import Palette from "../../assets/images/clutches/Palette.jpg";
import BubbleClutch from "../../assets/images/clutches/BubbleClutch.jpg";
import PockerChip from "../../assets/images/clutches/PockerChip.jpg";
import VioletBilliardTable from "../../assets/images/clutches/VioletBilliardTable.jpg";
import BlackQueen from "../../assets/images/clutches/BlackQueen.jpg";
import Dice from "../../assets/images/clutches/Dice.jpg";
import Checker from "../../assets/images/clutches/Checker.jpg";
import puzzle from "../../assets/images/clutches/3DPuzzle.jpg";
import Grace from "../../assets/images/clutches/Grace.jpg";
import Alea from "../../assets/images/clutches/Alea.jpg";
import Clarisse from "../../assets/images/clutches/Clarisse.jpg";
import Laia from "../../assets/images/clutches/Laia.jpg";
import Anne from "../../assets/images/clutches/Anne.jpg";
import Aline from "../../assets/images/clutches/Aline.jpg";
import Gaia from "../../assets/images/clutches/Gaia.jpg";
import Eve from "../../assets/images/clutches/Eve.jpg";
import Valeria from "../../assets/images/clutches/Valeria.jpg";
import Fabianne from "../../assets/images/clutches/Fabianne.jpg";
import Aurore from "../../assets/images/clutches/Aurore.jpg";
import Sophie from "../../assets/images/clutches/Sophie.jpg";
import Cloe from "../../assets/images/clutches/Cloe.jpg";
import Amanda from "../../assets/images/clutches/Amanda.jpg";
import Gala1 from "../../assets/images/clutches/nuit/Gala1.jpg";
import Gala2 from "../../assets/images/clutches/nuit/Gala2.jpg";
import Gala3 from "../../assets/images/clutches/nuit/Gala3.jpg";
import Venus1 from "../../assets/images/clutches/nuit/Venus1.jpg";
import Venus2 from "../../assets/images/clutches/nuit/Venus2.jpg";
import Venus3 from "../../assets/images/clutches/nuit/Venus3.jpg";
import Venus4 from "../../assets/images/clutches/nuit/Venus4.jpg";
import Luce1 from "../../assets/images/clutches/nuit/Luce1.jpg";
import Luce2 from "../../assets/images/clutches/nuit/Luce2.jpg";
import Luce3 from "../../assets/images/clutches/nuit/Luce3.jpg";
import Luce4 from "../../assets/images/clutches/nuit/Luce4.jpg";
import Stelle1 from "../../assets/images/clutches/nuit/Stelle1.jpg";
import Stelle2 from "../../assets/images/clutches/nuit/Stelle2.jpg";
import Stelle3 from "../../assets/images/clutches/nuit/Stelle3.jpg";
import Stelle4 from "../../assets/images/clutches/nuit/Stelle4.jpg";
import Parasole1 from "../../assets/images/clutches/estate/Parasole1.jpg";
import Parasole2 from "../../assets/images/clutches/estate/Parasole2.jpg";
import Parasole3 from "../../assets/images/clutches/estate/Parasole3.jpg";
import LPM1 from "../../assets/images/clutches/lpm/LPM1.JPG";
import LPM2 from "../../assets/images/clutches/lpm/LPM2.JPG";
import TereClassic1 from "../../assets/images/clutches/teresa/TereClassic1.jpg";
import TereClassic2 from "../../assets/images/clutches/teresa/TereClassic2.jpg";
import TereClassic3 from "../../assets/images/clutches/teresa/TereClassic3.jpg";
import TereClassic4 from "../../assets/images/clutches/teresa/TereClassic4.jpg";
import TereSquare1 from "../../assets/images/clutches/teresa/TereSquare1.jpg";
import TereSquare2 from "../../assets/images/clutches/teresa/TereSquare2.jpg";
import Cocoa1 from "../../assets/images/clutches/candy/Cocoa1.jpg";
import Cocoa2 from "../../assets/images/clutches/candy/Cocoa2.jpg";
import Cocoa3 from "../../assets/images/clutches/candy/Cocoa3.jpg";
import Cocoa4 from "../../assets/images/clutches/candy/Cocoa4.jpg";
import Cocoa5 from "../../assets/images/clutches/candy/Cocoa5.jpg";
import Cocoa6 from "../../assets/images/clutches/candy/Cocoa6.jpg";
import Palette1 from "../../assets/images/clutches/candy/Palette1.jpg";
import Palette2 from "../../assets/images/clutches/candy/Palette2.jpg";
import Palette3 from "../../assets/images/clutches/candy/Palette3.jpg";
import Palette4 from "../../assets/images/clutches/candy/Palette4.jpg";
import Palette5 from "../../assets/images/clutches/candy/Palette5.jpg";
import Palette6 from "../../assets/images/clutches/candy/Palette6.jpg";
import Bubble7 from "../../assets/images/clutches/candy/Bubble7.jpg";
import Bubble8 from "../../assets/images/clutches/candy/Bubble8.jpg";
import Bubble9 from "../../assets/images/clutches/candy/Bubble9.jpg";
import Bubble10 from "../../assets/images/clutches/candy/Bubble10.jpg";
import Bubble11 from "../../assets/images/clutches/candy/Bubble11.jpg";
import Bubble12 from "../../assets/images/clutches/candy/Bubble12.jpg";
import Bubble13 from "../../assets/images/clutches/candy/Bubble13.jpg";
import Bubble14 from "../../assets/images/clutches/candy/Bubble14.jpg";
import Bubble15 from "../../assets/images/clutches/candy/Bubble15.jpg";
import Bubble16 from "../../assets/images/clutches/candy/Bubble16.jpg";
import Bubble17 from "../../assets/images/clutches/candy/Bubble17.jpg";
import Bubble18 from "../../assets/images/clutches/candy/Bubble18.jpg";
import Pocker1 from "../../assets/images/clutches/play/Pocker1.jpg";
import Pocker2 from "../../assets/images/clutches/play/Pocker2.jpg";
import Pocker3 from "../../assets/images/clutches/play/Pocker3.jpg";
import Pocker4 from "../../assets/images/clutches/play/Pocker4.jpg";
import Pocker5 from "../../assets/images/clutches/play/Pocker5.jpg";
import Pocker6 from "../../assets/images/clutches/play/Pocker6.jpg";
import Billiard1 from "../../assets/images/clutches/play/Billiard1.jpg";
import Billiard2 from "../../assets/images/clutches/play/Billiard2.jpg";
import Billiard3 from "../../assets/images/clutches/play/Billiard3.jpg";
import Billiard4 from "../../assets/images/clutches/play/Billiard4.jpg";
import Queen1 from "../../assets/images/clutches/play/Queen1.jpg";
import Queen2 from "../../assets/images/clutches/play/Queen2.jpg";
import Queen3 from "../../assets/images/clutches/play/Queen3.jpg";
import Queen4 from "../../assets/images/clutches/play/Queen4.jpg";
import Dice1 from "../../assets/images/clutches/play/Dice1.jpg";
import Dice2 from "../../assets/images/clutches/play/Dice2.jpg";
import Dice3 from "../../assets/images/clutches/play/Dice3.jpg";
import Dice4 from "../../assets/images/clutches/play/Dice4.jpg";
import Checker1 from "../../assets/images/clutches/play/Checker1.jpg";
import Checker2 from "../../assets/images/clutches/play/Checker2.jpg";
import Checker3 from "../../assets/images/clutches/play/Checker3.jpg";
import Checker4 from "../../assets/images/clutches/play/Checker4.jpg";
import Puzzle1 from "../../assets/images/clutches/play/Puzzle1.jpg";
import Puzzle2 from "../../assets/images/clutches/play/Puzzle2.jpg";
import Grace1 from "../../assets/images/clutches/mont/Grace1.jpg";
import Grace2 from "../../assets/images/clutches/mont/Grace2.jpg";
import Grace3 from "../../assets/images/clutches/mont/Grace3.jpg";
import Laia1 from "../../assets/images/clutches/mont/Laia1.jpg";
import Clarisse1 from "../../assets/images/clutches/mont/Clarisse1.jpg";
import Clarisse2 from "../../assets/images/clutches/mont/Clarisse2.jpg";
import Alea1 from "../../assets/images/clutches/mont/Alea1.jpg";
import Alea2 from "../../assets/images/clutches/mont/Alea2.jpg";
import Anne1 from "../../assets/images/clutches/anne/Anne1.jpg";
import Anne2 from "../../assets/images/clutches/anne/Anne2.jpg";
import Anne3 from "../../assets/images/clutches/anne/Anne3.jpg";
import Anne4 from "../../assets/images/clutches/anne/Anne4.jpg";
import Gaia1 from "../../assets/images/clutches/vie/Gaia1.jpg";
import Gaia2 from "../../assets/images/clutches/vie/Gaia2.jpg";
import Aline1 from "../../assets/images/clutches/vie/Aline1.jpg";
import Aline2 from "../../assets/images/clutches/vie/Aline2.jpg";
import Aline3 from "../../assets/images/clutches/vie/Aline3.jpg";
import Valeria1 from "../../assets/images/clutches/ladies/Valeria1.jpg";
import Valeria2 from "../../assets/images/clutches/ladies/Valeria2.jpg";
import Valeria3 from "../../assets/images/clutches/ladies/Valeria3.jpg";
import Fabianne1 from "../../assets/images/clutches/ladies/Fabianne1.jpg";
import Fabianne2 from "../../assets/images/clutches/ladies/Fabianne2.jpg";
import Sophie1 from "../../assets/images/clutches/ladies/Sophie1.jpg";
import Sophie2 from "../../assets/images/clutches/ladies/Sophie2.jpg";
import Cloe1 from "../../assets/images/clutches/ladies/Cloe1.jpg";
import Cloe2 from "../../assets/images/clutches/ladies/Cloe2.jpg";
import Amanda1 from "../../assets/images/clutches/ladies/Amanda1.jpg";
import Amanda2 from "../../assets/images/clutches/ladies/Amanda2.jpg";
import Aurore1 from "../../assets/images/clutches/ladies/Aurore1.jpg";
import Aurore2 from "../../assets/images/clutches/ladies/Aurore2.jpg";

const items = [
  {
    id: 1,
    name: "Gala",
    image: Gala,
    price: 336.0,
    images: [
      Gala1,
      Gala2,
      Gala3,
    ],
    description: 'Oval-shaped model in black acrylic with white and gold details.',
  },
  {
    id: 2,
    image: Venus,
    name: "Venus",
    price: 435.0,
    images: [
      Venus1,
      Venus2,
      Venus3,
      Venus4,
    ],
    description: 'Diamond-shaped in black acrylic with internal decorative elements in black and white, with gold appliques around.'
  },
  {
    id: 3,
    image: Luce,
    name: "Luce",
    price: 588.0,
    images: [
      Luce1,
      Luce2,
      Luce3,
      Luce4,
    ],
    description: 'Model star-shaped based in black acrylic with white appliques and golden details in low relief.'
  },
  {
    id: 4,
    image: Stelle,
    name: "Stelle",
    price: 588.0,
    images: [
      Stelle1,
      Stelle2,
      Stelle3,
      Stelle4,
    ],
    description: 'Model star-shaped based in black acrylic with white appliques and golden details in low relief.'
  },
  {
    id: 5,
    image: Parasole,
    name: "Parasole",
    price: 588.0,
    images: [
      Parasole1,
      Parasole2,
      Parasole3,
    ],
    // wood color
    description: 'Rectangular clutch made out of pressed recycled wood.'
  },
  {
    id: 6,
    image: LPM,
    name: "LPM",
    price: 588.0,
    images: [
      LPM1,
      LPM2,
    ],
    description: 'A tridimensional rombo form clutch, made out of 36 pieces to create a piece of art.'
  },
  {
    id: 7,
    image: TereClassic,
    name: "Teresa Classic",
    price: 588.0,
    images: [
      TereClassic1,
      TereClassic2,
      TereClassic3,
      TereClassic4,
    ],
    colors: ['#000', '#0f4c81', '#f5f5f5', '#80471c'],
    description: 'Rectangle shaped clutch with acrylic surrounding, hatched leather detail on one face. '
  },
  {
    id: 8,
    image: TereSquare,
    name: "Teresa Square",
    price: 588.0,
    images: [
      TereSquare1,
      TereSquare2,
    ],
    description: 'Squared shaped clutch with acrylic surrounding, hatched leather detail on one face.    '
  },
  {
    id: 9,
    image: TereRect,
    name: "Teresa Rectangular",
    price: 588.0,
    images: [

    ],
    description: 'Rectangle shaped clutch with acrylic surrounding, hatched leather detail on one face.    '
  },
  {
    id: 10,
    image: TereHexa,
    name: "Teresa Hexagonal",
    price: 588.0,
    images: [
    ],
    description: 'Hexagonal shaped clutch with acrylic surrounding, hatched leather detail on one face.    '
  },
  {
    id: 11,
    image: Hannah,
    name: "Hanna",
    price: 588.0,
    images: [],
    description: 'Big hexagonal leather clutch. Two colored.'
  },
  {
    id: 12,
    image: CocoaCaramel,
    name: "Cocoa Caramel",
    price: 588.0,
    images: [
      Cocoa1,
      Cocoa4,
      Cocoa3,
      Cocoa2,
      Cocoa5,
      Cocoa6
    ],
    colors: ['#000', '#ff0000','#0f4c81'],
    description: 'Candy Shaped box in red and black acrylic color, details in gold color and sconces details infront ” Cocoa Caramel Mint” in gold in bas-relief inlaid.'
  },
  {
    id: 13,

    image: Palette,
    name: "Palette",
    price: 588.0,
    images: [
      Palette6,
      Palette3,
      Palette1,
      Palette2,
      Palette5,
      Palette4,
    ],
    colors: ['#000', '#ff0000'],
    description: 'Palette Shaped Box in red and black acrylic with details in golden color, work in low relief.'
  },
  {
    id: 14,
    image: BubbleClutch,
    name: "Bubble Clutch",
    price: 588.0,
    images: [
      Bubble7,
      Bubble12,
      Bubble16,
      Bubble10,
      Bubble11,
      Bubble8,
      Bubble13,
      Bubble14,
      Bubble15,
      Bubble9,
      Bubble17,
      Bubble18,
    ],
    colors: ['#000', '#0f4c81', '#f5f5f5'],
    description: 'Rectangular acrylic black or white box with internal decorative with movement elements in white, gold and navy blue. Closure balls in special resin with metallic art in navy blue and gold.'
  },
  {
    id: 15,
    image: PockerChip,
    name: "Pocker Chip",
    price: 588.0,
    images: [
      Pocker1,
      Pocker3,
      Pocker5,
      Pocker2,
      Pocker4,
      Pocker6
    ],
    colors: ['#ff0000', '#EE82EE','#ac824e'],
    description: 'Black, Red, Violet and golden acrylic round clutch with acrylic details of heart and trevol poker chip on front.'
  },
  {
    id: 16,
    image: VioletBilliardTable,
    name: "Billiard Table",
    price: 588.0,
    images: [
      Billiard1,
      Billiard3,
      Billiard2,
      Billiard4,
    ],
    colors: ['#EE82EE', '#808000'],
    description: 'Rectangle shaped acrylic, leather on front and billiard balls details.'
  },
  {
    id: 17,
    image: BlackQueen,
    name: "Queen",
    price: 588.0,
    images: [
      Queen1,
      Queen3,
      Queen2,
      Queen4,
    ],
    colors: ['#f5f5f5', '#000'],
    description: 'Rectangle shaped acrylic, black and white details with half chess queen piece on top. '
  },
  {
    id: 18,
    image: Dice,
    name: "Dice",
    price: 588.0,
    images: [
      Dice3,
      Dice1,
      Dice2,
      Dice4,
    ],
    colors: ['#f5f5f5', '#000'],
    description: 'Square shaped clear acrylic, black dots and gold plated details.'
  },
  {
    id: 19,
    image: Checker,
    name: "Checker",
    price: 588.0,
    images: [
      Checker1,
      Checker3,
      Checker2,
      Checker4,
    ],
    colors: ['#000', '#56070C'],
    description: 'Round wood box, shaped as a checker table'
  },
  {
    id: 20,
    image: puzzle,
    name: "3D Puzzle",
    price: 588.0,
    images: [
      Puzzle1,
      Puzzle2,
    ],
    description: 'Rectangle shaped acrylic, colorful 3D puzzle shape details on front.'
  },
  {
    id: 21,
    image: Grace,
    name: "Grace",
    price: 588.0,
    images: [
      Grace1,
      Grace2,
      Grace3,
    ],
    colors: ['#f5f5f5', '#E25822', '#e7b88d'],
    description: 'Rectangle shaped clutch with black acrylic surrounding, black textured leather backdrop with details in mirror acrylic and marble technique.'
  },
  {
    id: 22,
    image: Alea,
    name: "Alea",
    price: 588.0,
    images: [
      Alea1,
      Alea2,
    ],
    description: 'Rectangle shaped clutch with black or white acrylic surrounding, details in multicolor mirror acrylic.'
  },
  {
    id: 23,
    image: Clarisse,
    name: "Clarisse",
    price: 588.0,
    images: [
      Clarisse1,
      Clarisse2,
    ],
    description: 'Rectangle shaped clutch with black acrylic surrounding, black textured leather backdrop with details in mirror acrylic.'
  },
  {
    id: 24,
    image: Laia,
    name: "Laia",
    price: 588.0,
    images: [
      Laia1,
    ],
    description: 'Rectangle shaped clutch with black or white acrylic surrounding, details in multicolor mirror acrylic.'
  },
  {
    id: 25,
    image: Anne,
    name: "Anne",
    price: 588.0,
    images: [
      Anne1,
      Anne2,
      Anne3,
      Anne4,
    ],
    description: 'Irregular shaped clutch with acrylic surrounding.'
  },
  {
    id: 26,
    image: Aline,
    name: "Aline",
    price: 588.0,
    images: [
      Aline1,
      Aline2,
      Aline3,
    ],
    description: 'A piece inspired by the four natural elements represented by acrylic, leather, wood and semi precious stones like amber. Using relief in the placement of each of material.'
  },
  {
    id: 27,
    image: Gaia,
    name: "Gaia",
    price: 588.0,
    images: [
      Gaia1,
      Gaia2,
    ],
    colors: ['#e7b88d', '#007fff'],
    description: 'A piece inspired by the four natural elements represented by acrylic, leather, wood and semi precious stones like amber. Using relief in the placement of each of material.'
  },
  {
    id: 28,
    image: Eve,
    name: "Eve",
    price: 588.0,
    images: [

    ],
    description: 'Round shaped clutch. Leather backdrop, acrylic base. Details with wood, acrylic, and semi precious stones such as amber.'
  },
  {
    id: 29,
    image: Valeria,
    name: "Valeria Red Kiss",
    price: 588.0,
    images: [
      Valeria1,
      Valeria2,
      Valeria3,
    ],
    colors: ['#000', '#f5f5f5'],
    description: 'Black or white acrylic rectangular clutch with a lip design on front. The lips figure has been placed in a different height to add more realism and personality to the clutch. '
  },
  {
    id: 30,
    image: Fabianne,
    name: "Fabianne",
    price: 588.0,
    images: [
      Fabianne1,
      Fabianne2,
    ],
    description: 'Red octagonal shaped acrylic clutch, with multicolor pieces at the center. '
  },
  {
    id: 31,
    image: Sophie,
    name: "Sophie",
    price: 588.0,
    images: [
      Sophie1,
      Sophie2,
    ],
    description: 'A rectangular-squared form with a “play” music design. '
  },
  {
    id: 32,
    image: Cloe,
    name: "Cloe",
    price: 588.0,
    images: [
        Cloe1,
        Cloe2,
    ],
    description: 'Round clutch with a “play” music design. '
  },
  {
    id: 33,
    image: Amanda,
    name: "Amanda",
    price: 588.0,
    images: [
      Amanda1,
      Amanda2,
    ],
    description: 'Rectangular black clutch with a precise and detailed colorful design.  '
  },
  {
    id: 34,
    image: Aurore,
    name: "Aurore",
    price: 588.0,
    images: [
      Aurore1,
      Aurore2,
    ],
    description: 'Multicolor triangular shaped clutch. '
  },
];
const ClutchesPage = () => {
  return (
    <React.Fragment>
      <div className="container-fluid">
        <img
          className="rounded"
          width={"100%"}
          src={ClutchesBanner}
          alt="bags banner"
        />
      </div>
      <ClutchesPage.MessageContainer>
        <ClutchesPage.Message>
          I like to create trends, not follow them.
        </ClutchesPage.Message>
        <ClutchesPage.Yoanny> - Yoanny Garcia </ClutchesPage.Yoanny>
      </ClutchesPage.MessageContainer>

      <ClutchesPage.Container>
        <div className="mt-3 page-content">
          <div className="container-fluid">
            {/*<ShopToolBox/>*/}
            <div className="products">
              <div className="row">
                {items.map((item) => (
                  <ShopProduct key={item.id} item={item} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </ClutchesPage.Container>
    </React.Fragment>
  );
};

ClutchesPage.MessageContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  width: 100%;
  height: 100%;
  min-height: 5vh;
  max-height: 8vh;
`;

ClutchesPage.Message = styled.h3`
  font-family: "ConmorantGaramontLight" !important;
  font-size: 35px;
  font-weight: 600;
  color: #2e2e2e;
  text-align: center;
  position: relative;
  top: 25px;
  z-index: 2;
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

ClutchesPage.Yoanny = styled.p`
  font-family: "ConmorantGaramontLight" !important;
  font-size: 25px;
  font-weight: 200;
  color: #2e2e2e;
  text-align: justify;
  margin-bottom: 1.5rem;
  z-index: 2;

  @media (max-width: 768px) {
    font-size: 1.5rem;
    top: 20px;
  }
`;

ClutchesPage.CarouselContainer = styled.div`
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  @media (max-width: 768px) {
    width: 95%;
    height: 55%;
    align-items: center;
    display: block;
  }
`;
ClutchesPage.Carousel = styled(ShopSlider)`
  width: 100%;
  height: 100%;
`;

ClutchesPage.Container = styled.div`
  width: 97%;
  height: 100%;
  //position: relative;
  min-height: 80vh;
  //position: relative;
  left: 0;
  top: 70px;
  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    position: relative;
    left: 0;
    top: 0;
  }
`;
export default ClutchesPage;
