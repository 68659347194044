import logo from './logo.svg';
import {MetaTags} from "react-meta-tags";
import {BrowserRouter, Link, Navigate, Route, Routes as Switch, useLocation} from "react-router-dom";
// Plugin Styless
import 'react-toastify/dist/ReactToastify.css';
import 'line-awesome/dist/line-awesome/css/line-awesome.min.css'
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/owl-carousel.css"
import './App.css';

import { Footer, Header, ScrollToTop} from "./components";
import {ShopPage, YGWorldPage, HomePage} from "./pages";
import ProductDetails from './pages/ProductDetails/ProductDetailsPage';
import ClutchesPage from './pages/ShopPage/ClutchesPage';
import TotesPage from './pages/ShopPage/TotesPage';
import BeltBagsPage from './pages/ShopPage/BeltBagsPage';
import StrapsPage from './pages/ShopPage/StrapsPage';
import PressPage from './pages/PressPage/PressPage';
import CommingSoonPage from "./pages/CommingSoonPage/CommingSoonPage";
function App() {

  return (
    
    <BrowserRouter>
        <div className="App">
            <CommingSoonPage/>
            {/*<div className="page-wrapper">*/}
            {/*    <MetaTags>*/}
            {/*        <title>YG Yoanny Garcia - Homepage</title>*/}
            {/*        <meta name="descrption" content="#1 Handmade Luxury Bags Brand in Dominincan Republic."/>*/}
            {/*        <meta property="og:title" content="YG Yoanny Garcia"/>*/}
            {/*        <meta property="og:image" content="https://static.wixstatic.com/media/3ee29e_05ca522161304897850b5869de544461~mv2.png/v1/fill/w_308,h_228,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Imagen1.png"/>*/}
            {/*    </MetaTags>*/}
            {/*    <ScrollToTop/>*/}
            {/*    <Header/>*/}
            {/*    <Switch>*/}
            {/*        <Route path="/" element={<HomePage/>}/>*/}
            {/*        <Route path="*" element={<Navigate to="/" replace />} />*/}
            {/*        <Route path="/about" element={<YGWorldPage/>} />*/}
            {/*        <Route path="/shop/handbags" element={<ShopPage/>} />*/}
            {/*        <Route path="/shop/clutches" element={<ClutchesPage/>} />*/}
            {/*        <Route path="/shop/totes" element={<TotesPage/>} />*/}
            {/*        <Route path="/shop/beltbags" element={<BeltBagsPage/>} />*/}
            {/*        <Route path="/shop/straps" element={<StrapsPage/>} />*/}

            {/*        <Route path="/shop/:itemname" element={<ProductDetails/>} />*/}
            {/*        <Route path='/press' element={<PressPage/>} />*/}

            {/*    </Switch>*/}

            {/*    <Footer/>*/}
            {/*</div>*/}
        </div>
    </BrowserRouter>
  );
}

export default App;
