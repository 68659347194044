import { Link } from "react-router-dom";
import styled from "styled-components";
import banner from "../../assets/images/newsletter.jpg";

const Newsletter = () => {
  return (
    <>
    {/* <BrandsBanner /> */}
    <div className="mb-3 mb-lg-5"></div>

    <div className="container newsletter">
        <div className="row">
            <div className="col-lg-6 banner-overlay-div">
                <div className="banner banner-overlay">
                    <a href="/">
                        <img src={banner} alt="Banner" />
                    </a>

                </div>
            </div>

            <div className="col-lg-6 d-flex align-items-stretch subscribe-div">
                <div className="cta cta-box">
                    <div className="cta-content">
                        <h3 className="cta-title">Subscribe To Our Newsletter</h3>

                        <form action="#">
                            <input type="email" className="form-control" placeholder="Enter your Email Address" aria-label="Email Adress" required />
                            <div className="text-center">
                                <button className="btn btn-outline-dark-2" type="submit"><span>subscribe</span><i /></button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</>
  );
};

export default Newsletter;
