import { useState } from "react";
import { StarIcon } from "@heroicons/react/20/solid";
import { RadioGroup } from "@headlessui/react";
import { useLocation } from "react-router-dom";
import "./ui.css";
import { MetaTags } from "react-meta-tags";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const product = {
  id: "1",
  name: "Handmade Clutch",
  href: "#",
  price: "$120.00",
  description:
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam quae, voluptates, voluptas, quod voluptatibus quibusdam quidem natus voluptatum quia quos quas. Quisquam, quae. Quisquam quae, voluptates, voluptas, quod voluptatibus quibusdam quidem natus voluptatum quia quos quas. Quisquam, quae.",
  images: [
    {
      id: "1",
      src: "https://tailwindui.com/img/ecommerce-images/product-page-01-product-shot-01.jpg",
      alt: "Front of tan leather pouch with black zipper, gray zipper pull, and adjustable hip belt.",
    },
    // More images...
  ],
  breadcrumbs: [
    { id: 1, name: "Home", href: "#", current: false },
    { id: 2, name: "Pouches", href: "#", current: false },
    { id: 3, name: "Handmade Clutch", href: "#", current: true },
  ],
  colors: [
    { name: "Tan", color: "bg-tan-500", selectedColor: "bg-tan-600" },
    { name: "Brown", color: "bg-brown-500", selectedColor: "bg-brown-600" },
    { name: "White", color: "bg-white", selectedColor: "bg-gray-100" },
    { name: "Black", color: "bg-black", selectedColor: "bg-gray-900" },
  ],
  sizes: [
    { name: "S", description: '12.5" x 9.5" x 3.5"' },
    { name: "M", description: '14" x 11" x 4"' },
    { name: "L", description: '15.5" x 12.5" x 4.5"' },
  ],
  reviews: [
    {
      id: 1,
      name: "Margot Foster",
      date: "July 20, 2020",
      rating: 5,
      message:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
    },
    // More reviews...
  ],
};

const ProductDetails = (props) => {
  // const product = useLocation().state.product
  const { state } = useLocation();
  const [selectedColor, setSelectedColor] = useState(product.colors[0]);
  const [selectedSize, setSelectedSize] = useState(product.sizes[0]);
  const [selectedImage, setSelectedImage] = useState(
    state.item.images[0] ? state.item.images[0] : state.item.image
  );

  console.log(state
    )
  return (
    <>
      <MetaTags>
        <title key="title">YG Yoanny Garcia - {state.item.name}</title>
      </MetaTags>
      <section className="mt-3 section-content padding-y bg">
        <div className="container-fluid">
          <article className="card">
            <div className="card-body1">
              <div className="row">
                <aside className="mt-1 col-md-6">
                  <article className="gallery-wrap">
                    <div className="card img-big-wrap">
                      <a>
                        <img
                          width={360}
                          height={360}
                          style={{ cursor: "default" }}
                          src={selectedImage}
                        />
                      </a>
                    </div>
                    <div className="thumbs-wrap">
                      {state.item.images.map((image, i) => (
                        <a
                          key={i}
                          onClick={() => setSelectedImage(image)}
                          className="item-thumb"
                        >
                          {" "}
                          <img style={{ cursor: "default" }} src={image} />
                        </a>
                      ))}
                    </div>
                  </article>
                </aside>
                <main className="mt-1 col-md-6">
                  <article>
                    <h3 className="title">{state.item.name}</h3>

                    {/* Color Palette */}
                    <div className="mt-1 mb-2">
                      {state.item.colors && (
                        <h6>Color</h6>
                      )} 

                      <div className="product-nav product-nav-dots">
                        {/* top color tes */}
                        {state.item.colors?.map((color, i) => (
                          <span
                            key={i}
                            style={{ background: color }}
                            onClick={() =>
                              setSelectedImage(state.item.images[i])
                            }
                          >
                            <span className="sr-only">Color name</span>
                          </span>
                        ))}
                      </div>
                    </div>

                    <div className="container">
                    {state.item.sizes && (
                    <div className="mt-1 mb-2">
                    <h6>Size</h6>
                    <div className="product-nav product-nav-dots">
                      
                      {state.item.sizes.map((size, i) => (
                        <button key={i} className="btn btn-sm btn-outline-primary">
                          {size}
                        </button>
                      ))}
                    </div>
                  </div>

                    )}
                    </div>
                    <div className="mt-1 mb-2">
                      <h6>Short description</h6>
                      <ul className="mb-0 list-dots">
                        <li>Luxuty bag made in Dominican Republic</li>
                        <li>Made with Acrylic and 100% Leather</li>
                      </ul>
                    </div>

                    <div className="mb-4">
                      {/* <a type='button' href='https://api.whatsapp.com/send/?phone=18094561857&text=Hi,%20i%27m%20interested%20on%20this%20purse' className="mr-1 btn btn-primary">Buy Now</a> */}
                    </div>
                  </article>
                </main>
              </div>
            </div>
          </article>

          <article className="mt-3 border rounded-lg card">
            <div className="m-2 card-body">
              <div className="row">
                <aside className="col-md-6">
                  <h5>Package Contents</h5>
                  <dl className="row">
                    <dd className="col-sm-12">
                      {" "}
                      Microfiber Cloth and Dust Bag to Clean your Purse{" "}
                    </dd>

                    <dd className="col-sm-9"> Warranty Card </dd>

                    <dd className="col-sm-9">
                      {" "}
                      Gold Plated Yoanny Garcia Mark Inside{" "}
                    </dd>

                    <dd className="col-sm-9"> Crossbody Leather Chain </dd>

                    <dd className="col-sm-9"> Magnetic Closure </dd>
                  </dl>
                </aside>
                <aside className="col-md-6">
                  <h5>Features</h5>
                  <ul>
                    <li className="flex justify-start">
                      <svg
                        className="m-[8px]"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        stroke="currentColor"
                        strokeWidth="2"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <polyline points="20 6 9 17 4 12"></polyline>
                      </svg>
                      <span className="ml-1">Luxury Handbag</span>
                    </li>
                    <li className="flex justify-start">
                      <svg
                        className="m-[8px]"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        stroke="currentColor"
                        strokeWidth="2"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <polyline points="20 6 9 17 4 12"></polyline>
                      </svg>
                      <span className="ml-1">
                        Handmade with the best quality
                      </span>
                    </li>
                    <li className="flex justify-start">
                      <svg
                        className="m-[9px]"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        stroke="currentColor"
                        strokeWidth="2"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <polyline points="20 6 9 17 4 12"></polyline>
                      </svg>
                      <span className="ml-1">
                        Unique Exclusive Leather Catalog
                      </span>
                    </li>
                  </ul>
                </aside>
              </div>
              <div className="mt-2">
                <p>{state.item.description}</p>
              </div>
            </div>
          </article>
        </div>
      </section>
    </>
  );
};

export default ProductDetails;
