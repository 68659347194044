import React from "react";
import styled from "@emotion/styled";
import {ShopProduct, ShopSlider, ShopToolBox} from "../../components";
import BeltBagBanner from '../../assets/images/banner-beltbag.png'
import ReginaMariaBeltBag from '../../assets/images/beltbags/reginabeltbag.jpg'
import Regina from '../../assets/images/beltbags/regina/regina.jpg'
const items = [
    {
        id: 1,
        image: ReginaMariaBeltBag,
        name: "Regina Belt Bag",
        price: 0.00,
        colors: [],
        images: [Regina],
    }, 
    ];
const BeltBagsPage = () => {
    return <React.Fragment>
        <div className="container-fluid">
            <img className="rounded" width={'100%'} src={BeltBagBanner} alt="bags banner"/>
            </div>
        <BeltBagsPage.MessageContainer>
            <BeltBagsPage.Message>
                I like to create trends, not follow them.
            </BeltBagsPage.Message>
            <BeltBagsPage.Yoanny> - Yoanny Garcia </BeltBagsPage.Yoanny>
        </BeltBagsPage.MessageContainer>

        <BeltBagsPage.Container>
            <div className="mt-3 page-content">

                <div className="container-fluid">
                    {/*<ShopToolBox/>*/}
                    <div className="products">


                <div className="row">
                {items.map(item => <ShopProduct key={item.id} item={item}/>)}
                </div>
                </div>
                </div>
                </div>
        </BeltBagsPage.Container>

    </React.Fragment>;
}


BeltBagsPage.MessageContainer = styled.div`
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding-top: 20px;

    width: 100%;
    height: 100%;
    min-height: 5vh;
    max-height: 8vh;
`;

BeltBagsPage.Message = styled.h3`
    font-family: 'ConmorantGaramontLight' !important;
    font-size: 35px;
    font-weight: 600;
    color: #2e2e2e;
    text-align: center;
    position: relative;
    top: 25px;
    z-index:  2;
   @media (max-width: 768px) {
    font-size: 2rem;
     
   }
`;

BeltBagsPage.Yoanny = styled.p`
    font-family: 'ConmorantGaramontLight' !important;
    font-size: 25px;
    font-weight: 200;
    color: #2e2e2e;
    text-align: justify;
    margin-bottom: 1.5rem;
    z-index:  2;

    @media (max-width: 768px) {
        font-size: 1.5rem;
          top: 20px;
    }
`;

BeltBagsPage.CarouselContainer = styled.div`
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    @media (max-width: 768px) {
        width: 95%;
        height: 55%;
        align-items: center;
        display: block;
      
    }
`;
BeltBagsPage.Carousel = styled(ShopSlider)`
    width: 100%;
    height: 100%;

`;

BeltBagsPage.Container = styled.div`
    width: 97%;
    height: 100%;
    //position: relative;
    min-height: 80vh;
    //position: relative;
    left: 0;
    top: 70px;
    @media (max-width: 768px) {
        width: 100%;
        height: 100%;
        position: relative;
        left: 0;
        top: 0;
    }
        
`;
export default BeltBagsPage;