import React from "react";
import {Featured, HomeSlider, Newsletter, Video, Whatsapp} from "../../components";
import HandMadeVideo from "../../assets/videos/handmade.mp4";
import ClutchesVideo from "../../assets/videos/video.mp4";
import PressBrands from "../../components/PressBrands/PressBrands";
import styled from "styled-components"
import Leonore from "../../assets/images/collections/Leonore.jpg";

const HomePage = () => {
    return (
        <React.Fragment>
            <HomePage.Parallax image={Leonore}>
                <HomePage.Parallax.Title>Leonore</HomePage.Parallax.Title>
                <HomePage.Parallax.Subtitle>Limited Edition</HomePage.Parallax.Subtitle>
            </HomePage.Parallax>
            {/*<Carousel/>*/}
            <Featured/>
                <HomePage.Parallax>
                    <Video videoSrc={HandMadeVideo} rate={1} videoTitle={'HANDMADE WITH THE BEST HANDS'}/>
                </HomePage.Parallax>
                {/*<Video videoSrc={ClutchesVideo} rate={0.3}/>*/}
            <PressBrands/>
            <Newsletter/>

        </React.Fragment>
    );
}

HomePage.Parallax = styled.section`
  background-image: url(${props => props.image});
  background-size: cover;
  position: relative;
  min-height: 873px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
`;

HomePage.Parallax.Title = styled.h1`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 130px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: 0 0 10px #000;
`;

HomePage.Parallax.Subtitle = styled.h2`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: 0 0 10px #000;
`;


export default HomePage;