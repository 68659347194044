import React from "react";
import styled from "@emotion/styled";
import {ShopProduct, ShopSlider, ShopToolBox} from "../../components";
import Zoe from '../../assets/images/totes/Zoe.jpg'
import VittoriaTote from '../../assets/images/totes/VittoriaTote.jpg'
import Nara from '../../assets/images/totes/Nara.png'
import Kayha from '../../assets/images/totes/Kayha.png'
import Simone from '../../assets/images/totes/Simone.png'
import TotesBanner from '../../assets/images/banner-totes.png'
import VTote1 from '../../assets/images/totes/Vittoria/VTote1.jpg'
import VTote2 from '../../assets/images/totes/Vittoria/VTote2.jpg'
import VTote3 from '../../assets/images/totes/Vittoria/Vtote3.jpg'

import VTote4 from '../../assets/images/totes/Vittoria/Vtote4.jpg'
const items = [
    {
        id: 1,
        image: VittoriaTote,
        name: "Vittoria Tote",
        price: 531.00,
        description: 'Rectangular tote bag made out of leather or natural cork with appliques on front.',
        images: [
            VTote2,
            VTote3,
            VTote1,
            VTote4,
        ],
        colors: ["#000", "#0047ab"],

    },
    {
        id: 2,
        image: Zoe,
        name: "Zoe",
        price: 336.00,
        description: 'Leather bag with acrylic details on the front has fringes on the side.',
        images: [],
    },
    {
        id: 3,
        image: Nara,
        name: "Nara",
        price: 435.00,
        description: 'Tote leather bag with acrylic details on the front.',
        images: []
    },
    {
        id: 4,
        image: Kayha,
        name: "Kayha",
        price: 588.00,
        description: 'Leather bag with acrylic details on the front. Stripes on the front.',
        images: []
    },
    {
        id: 5,
        image: Simone,
        name: "Simone",
        price: 588.00,
        description: 'Leather bag with acrylic details on the front has fringes on the side.',
        images:[]
    },
    ];
const TotesPage = () => {
    return <React.Fragment>
        <div className="container-fluid">
            <img className="rounded" width={'100%'} src={TotesBanner} alt="bags banner"/>
            </div>
        <TotesPage.MessageContainer>
            <TotesPage.Message>
                I like to create trends, not follow them.
            </TotesPage.Message>
            <TotesPage.Yoanny> - Yoanny Garcia </TotesPage.Yoanny>
        </TotesPage.MessageContainer>

        <TotesPage.Container>
            <div className="mt-3 page-content">

                <div className="container-fluid">
                    {/*<ShopToolBox/>*/}
                    <div className="products">


                <div className="row">
                {items.map(item => <ShopProduct key={item.id} item={item}/>)}
                </div>
                </div>
                </div>
                </div>
        </TotesPage.Container>

    </React.Fragment>;
}


TotesPage.MessageContainer = styled.div`
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 5vh;
    padding-top: 20px;
    max-height: 8vh;
`;

TotesPage.Message = styled.h3`
    font-family: 'ConmorantGaramontLight' !important;
    font-size: 35px;
    font-weight: 600;
    color: #2e2e2e;
    text-align: center;
    position: relative;
    top: 25px;
    z-index:  2;
   @media (max-width: 768px) {
    font-size: 2rem;
     
   }
`;

TotesPage.Yoanny = styled.p`
    font-family: 'ConmorantGaramontLight' !important;
    font-size: 25px;
    font-weight: 200;
    color: #2e2e2e;
    text-align: justify;
    margin-bottom: 1.5rem;
    z-index:  2;

    @media (max-width: 768px) {
        font-size: 1.5rem;
          top: 20px;
    }
`;

TotesPage.CarouselContainer = styled.div`
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    @media (max-width: 768px) {
        width: 95%;
        height: 55%;
        align-items: center;
        display: block;
      
    }
`;
TotesPage.Carousel = styled(ShopSlider)`
    width: 100%;
    height: 100%;

`;

TotesPage.Container = styled.div`
    width: 97%;
    height: 100%;
    //position: relative;
    min-height: 80vh;
    //position: relative;
    left: 0;
    top: 70px;
    @media (max-width: 768px) {
        width: 100%;
        height: 100%;
        position: relative;
        left: 0;
        top: 0;
    }
        
`;
export default TotesPage;