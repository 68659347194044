import React from "react";
import Carousel from "react-spring-3d-carousel-2";
import {config} from "react-spring";
const ShopSlider = () => {
    const [goToSlide, setGoToSlide] = React.useState(0);
    const [offsetRadius, setOffsetRadius] = React.useState(4);
    const [cfg, setCfg] = React.useState(config.gentle);
    const [showNavigation, setShowNavigation] = React.useState(false);

    const slides = [
        {
            key: 1,
            content: <img src="https://static.wixstatic.com/media/3ee29e_14deb75142b3495983dd87aca6f6ba2a~mv2.jpg/v1/fill/w_506,h_314,al_c,q_80,usm_0.66_1.00_0.01/3ee29e_14deb75142b3495983dd87aca6f6ba2a~mv2.jpg" alt="1" />
        },
        {
            key: 2,
            content: <img src="https://static.wixstatic.com/media/3ee29e_bbe9cf25d8fb4f80a1daf1fcd53f03b9~mv2.jpg/v1/fill/w_506,h_314,al_c,q_80,usm_0.66_1.00_0.01/3ee29e_bbe9cf25d8fb4f80a1daf1fcd53f03b9~mv2.jpg" alt="2" />
        },
        {
            key: 3,
            content: <img src="https://static.wixstatic.com/media/3ee29e_e31a548872a74daca20914693fd5a6b2~mv2.jpg/v1/fill/w_506,h_314,al_c,q_80,usm_0.66_1.00_0.01/3ee29e_e31a548872a74daca20914693fd5a6b2~mv2.jpg" alt="3" />
        },
        {
            key: 4,
            content: <img src="https://static.wixstatic.com/media/3ee29e_04538aee4faa4c59bb8602b0e9656e8d~mv2.jpg/v1/fill/w_506,h_314,al_c,q_80,usm_0.66_1.00_0.01/3ee29e_04538aee4faa4c59bb8602b0e9656e8d~mv2.jpg" alt="4" />
        },
        {
            key: 5,
            content: <img
                src="https://static.wixstatic.com/media/3ee29e_ce83fcced0b24a59a3a69d78c16fbcdc~mv2.jpg/v1/fill/w_506,h_314,al_c,q_80,usm_0.66_1.00_0.01/3ee29e_ce83fcced0b24a59a3a69d78c16fbcdc~mv2.jpg"
                alt="5"/>
        },
        {
            key: 6,
            content: <img src="https://static.wixstatic.com/media/3ee29e_65c8baec323e40148ec29a5f7e171954~mv2.jpeg/v1/fill/w_506,h_314,al_c,q_80,usm_0.66_1.00_0.01/3ee29e_65c8baec323e40148ec29a5f7e171954~mv2.jpeg" alt="6" />

        },
        {
            key: 7,
            content: <img src="https://static.wixstatic.com/media/3ee29e_a7cbab28d78b415ab55fb7cf4ae4cd3b~mv2.jpg/v1/fill/w_506,h_314,al_c,q_80,usm_0.66_1.00_0.01/3ee29e_a7cbab28d78b415ab55fb7cf4ae4cd3b~mv2.jpg" alt="7" />
        }
    ].map((slide, index) => {
        return { ...slide, onClick: () => setGoToSlide(index) };
    });
    return (
        <div style={{ width: "100%", height: "200px"}}>
            <Carousel
                slides={slides}
                goToSlide={goToSlide}
                offsetRadius={offsetRadius}
                showNavigation={showNavigation}
                animationConfig={cfg}
                autoplay={true}
                autoplayDelay={2}
            />
        </div>
    )
}

export default ShopSlider;