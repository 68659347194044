import React from "react";
import styled from "@emotion/styled";
import {ShopProduct, ShopSlider, ShopToolBox} from "../../components";
import StrapsBanner from '../../assets/images/banner-straps.png'
import SilverStrap from '../../assets/images/straps/SilverStrap.jpg'
import YellowStrap from '../../assets/images/straps/YellowStrap.jpg'
import BlackStrap from '../../assets/images/straps/BlackStrap.jpg'
import BlueStrap from '../../assets/images/straps/BlueStrap.jpg'
const items = [
    {
        id: 1,
        image: SilverStrap,
        name: "Silver Strap",
        price: 0.00,
        images: [
            
        ]
    }, 
    {
        id: 2,
        image: YellowStrap,
        name: "Yellow Strap",
        price: 0.00,
        images: []
    },
    {
        id: 3,
        image: BlackStrap,
        name: "Black Strap",
        price: 0.00,
        images: []
    },
    {
        id: 4,
        image: BlueStrap,
        name: "Blue Strap",
        price: 0.00,
        images: []
    },
    ];
const StrapsPage = () => {
    return <React.Fragment>
        <div className="container-fluid">
            <img className="rounded" width={'100%'} src={StrapsBanner} alt="bags banner"/>
            </div>
        <StrapsPage.MessageContainer>
            <StrapsPage.Message>
                I like to create trends, not follow them.
            </StrapsPage.Message>
            <StrapsPage.Yoanny> - Yoanny Garcia </StrapsPage.Yoanny>
        </StrapsPage.MessageContainer>

        <StrapsPage.Container>
            <div className="mt-3 page-content">

                <div className="container-fluid">
                    {/*<ShopToolBox/>*/}
                    <div className="products">


                <div className="row">
                {items.map(item => <ShopProduct key={item.id} item={item}/>)}
                </div>
                </div>
                </div>
                </div>
        </StrapsPage.Container>

    </React.Fragment>;
}


StrapsPage.MessageContainer = styled.div`
    align-items: center;
    justify-content: center;
    display: flex;
    padding-top: 20px;

    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 5vh;
    max-height: 8vh;
`;

StrapsPage.Message = styled.h3`
    font-family: 'ConmorantGaramontLight' !important;
    font-size: 35px;
    font-weight: 600;
    color: #2e2e2e;
    text-align: center;
    position: relative;
    top: 25px;
    z-index:  2;
   @media (max-width: 768px) {
    font-size: 2rem;
     
   }
`;

StrapsPage.Yoanny = styled.p`
    font-family: 'ConmorantGaramontLight' !important;
    font-size: 25px;
    font-weight: 200;
    color: #2e2e2e;
    text-align: justify;
    margin-bottom: 1.5rem;
    z-index:  2;

    @media (max-width: 768px) {
        font-size: 1.5rem;
          top: 20px;
    }
`;

StrapsPage.CarouselContainer = styled.div`
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    @media (max-width: 768px) {
        width: 95%;
        height: 55%;
        align-items: center;
        display: block;
      
    }
`;
StrapsPage.Carousel = styled(ShopSlider)`
    width: 100%;
    height: 100%;

`;

StrapsPage.Container = styled.div`
    width: 97%;
    height: 100%;
    //position: relative;
    min-height: 80vh;
    //position: relative;
    left: 0;
    top: 70px;
    @media (max-width: 768px) {
        width: 100%;
        height: 100%;
        position: relative;
        left: 0;
        top: 0;
    }
        
`;
export default StrapsPage;